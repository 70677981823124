// @ts-nocheck
export default {
    welcome: "Karibu",
    email_address: "Anwani ya Barua Pepe",
    email_or_phone: "Anwani ya Barua Pepe au Nambari ya Simu",
    enter_your_email: "Ingiza Barua Pepe Yako",
    enter_your_email_or_phone: "Ingiza Anwani ya Barua Pepe au Nambari ya Simu",
    phone_placeholder: "Nambari ya simu",
    password: "Nenosiri",
    confirm_password: "Thibitisha Nenosiri",
    log_in: "Ingia",
    login: "Ingia",
    forgot_password: "Umesahau Nenosiri?",
    forgot_password_title: "Umesahau Nenosiri",
    keep_me_logged_in: "Nihifadhi Nikiwa Nimeingia",
    sign_in: "Ingia",
    not_registered: "Hujaandikishwa bado?",
    create_account: "Fungua Akaunti",
    sign_up: "Jiandikishe",
    first_name: "Jina la Kwanza",
    last_name: "Jina la Mwisho",
    dob_placeholder: "Tarehe ya Kuzaliwa (DD/MM/YYYY)",
    submit: "Wasilisha",
    already_have_account: "Tayari una akaunti?",
    why_need_dob_title: "Kwa nini tunahitaji hii?",
    why_need_dob_text: `Kwa kutumia Tovuti hii, unawakilisha, unakubali, na unakubali kuwa una angalau umri wa miaka 14, au ikiwa una umri wa chini ya miaka 18 lakini una angalau umri wa miaka 14 (Mtu Mdogo), kwamba unatumia Tovuti kwa idhini ya mzazi wako au mlezi wa kisheria na kwamba umepokea ruhusa ya mzazi wako au mlezi wa kisheria kutumia Tovuti na kukubali Masharti yake. Ikiwa wewe ni mzazi au mlezi wa kisheria wa Mtu Mdogo, unakubali kumfunga Mtu Mdogo kwa Masharti haya na kumfidia kabisa na kumshikilia Kampuni ikiwa Mtu Mdogo atavunja yoyote ya Masharti haya. Ikiwa huna angalau umri wa miaka 14, huwezi kutumia Tovuti wakati wowote au kwa njia yoyote au kuwasilisha taarifa yoyote kwa Kampuni au Tovuti. KUMBUKA: Kuna matukio mengine ya programu hii ambayo yanahitaji watumiaji kuwa na angalau umri wa miaka 14, angalau umri wa miaka 16, au angalau umri wa miaka 18. Ikiwa umekataliwa usajili baada ya kuingiza tarehe yako ya kuzaliwa ni kwa sababu lango maalum unalosajili lina moja ya mahitaji haya.`,
    go_back: "Rudi Nyuma",
    email_verification: "Uthibitishaji wa Barua Pepe",
    log_out: "Toka",
    resend_email_verification: "Tuma Tena Barua Pepe ya Uthibitishaji",
    resend_text_verification: "Tuma Tena Ujumbe wa Uthibitishaji",
    send_email_verification_instead:
        "Tuma Barua Pepe ya Uthibitishaji Badala Yake",
    send_text_verification_instead: "Tuma Ujumbe wa Uthibitishaji Badala Yake",
    reset_password: "Weka Upya Nenosiri",
    accept_invite: "Kubali Mwaliko",
    my_hep: "Mpango Wangu wa Mazoezi ya Nyumbani",
    plan_created: "Mpango Umeundwa",
    remove_plan: "Ondoa Mpango",
    remove_plan_confirm_text: "Una uhakika unataka kuondoa mpango huu?",
    duplicate_plan_check_text:
        "Tayari una mpango huu uliowekwa. Una uhakika unataka kuweka tena mpango huu?",
    duplicate_plan_modal: {
        yes_text: "Ndio",
        no_text: "Nenda kwenye Dashibodi",
    },
    translation_disclaimer: {
        title: "Kanusho la Tafsiri",
        subtitle: "Tafadhali kumbuka kuwa tafsiri zetu zinaendeshwa na programu ya AI, ambayo ni takriban 98%. Ili kuhakikisha mawasiliano yaliyo wazi na sahihi, tunashauri kuomba ufafanuzi kuhusu tafsiri zozote ambazo zinaonekana kuwa wazi au si sahihi. Inapendekezwa pia kutumia lugha rahisi na kuepuka misimu ili kupunguza kutoelewana kunakoweza kutokea.",
        accept_button_text: "NINAELEWA NA NAKUBALI KUTUMIA KWA HATARI YANGU MWENYEWE",
    },
    provider: "Mtoa Huduma",
    self_help_text: "Elimu ya Kujisaidia",
    show_all: "Onyesha Yote",
    no_record: "Hakuna rekodi iliyopatikana",
    no_heps: "Hapa ndipo utapata Mipango yako ya Urejeshaji ambayo iliundwa baada ya kukabidhiwa kwako na mhudumu wako wa afya au kujikabidhi mwenyewe kupitia zana zetu zozote za kujisaidia ambazo zinaweza kupatikana kwako. Kwa sasa haujakabidhiwa.",
    what_to_do: "Cha Kufanya",
    hold_time: "Muda wa Kushikilia",
    day_per_week: "Siku kwa Wiki",
    equipment: "Vifaa",
    sets: "Seti",
    reps: "Marudio",
    weight: "Uzito",
    in_pounds: "Kwa paundi",
    time_per_day: "Muda kwa Siku",
    starting_position: "Nafasi ya Kuanza",
    description: "Maelezo",
    what_you_feel: "Unachopaswa Kuhisi",
    too_easy: "Rahisi Sana",
    too_hard: "Ngumu Sana",
    just_right: "Sawa Kabisa",
    exercise: "Mazoezi",
    education: "Elimu",
    back_to_list: "Rudi kwenye orodha",
    about_this_exercise: "Unafikiria nini kuhusu zoezi hili?",
    attachement: "Kiambatisho",
    exercise_completed: "Zoezi Limekamilika",
    no_details: "Hakuna maelezo yanayopatikana.",
    hep_details: {
        hard_exercise_modal: {
            title: "Marekebisho Yanayohitajika kwa Zoezi.",
            description_line1:
                "Hili ndilo zoezi rahisi zaidi katika mkakati huu, kwa hivyo hatuwezi kutoa zoezi rahisi zaidi kwa sasa.",
            description_line2:
                "Ikiwa unapata zoezi lolote kuwa gumu sana, una wasiwasi, au huna uhakika wa nini cha kufanya baadaye, acha mara moja na wasiliana na Mtaalamu wa Afya au Mtoa Huduma wako aliyekupea mpango huu wa kupona.",
            btn_text: "Nimesoma na kuelewa mapendekezo haya",
        },
    },
    flash_card: {
        yes_text: "Ndio",
        no_text: "Hapana",
    },
    complete_strategy_modal: {
        title: "Hongera!",
        description: "Umekamilisha seti hii ya mazoezi.",
        btn_text: "Nenda kwenye Orodha ya hep",
    },
    talk_to_expert: {
        title: "Ongea na Mtaalamu",
        breadcrumb: "Ongea na Mtaalamu!",
        book_now: "Weka sasa",
        powered_by: "inaendeshwa na",
    },
    find_clinic: {
        title: "Tafuta Kliniki",
        search: "Ingiza eneo",
        breadcrumb: "Tafuta Kliniki",
        back_title: "Maeneo",
        result_text: "Matokeo ya Utafutaji",
        pagination_label: "Onyesha:",
        pagination_text: "Kuingia",
        toggle_list_map_label: "Tazama:",
        list_text: "Orodha",
        map_text: "Ramani",
        print_text: "chapisha",
        not_found: "Hakuna Kliniki Zilizopatikana",
    },
    messages: {
        title: "Ujumbe",
        new_message: "Ujumbe Mpya",
        welcome: "Karibu",
        you_are_signed_in_as: "Umeingia kama",
        hi: "Habari",
        you_are_disconnected: "Kwa sasa umeunganishwa kutoka kwenye mtandao.",
        practice: "Mazoezi",
        provider: "Mtoa Huduma",
        could_not_send_msg: "Haikuweza kutuma ujumbe.",
        some_files_removed: "Baadhi ya faili zilizo juu ya 100 MB zilitolewa.",
        say_hello: "Sema Habari",
        hello_text: "Habari!",
        start_conversation: "Anza mazungumzo yako mapya",
        send: "Tuma",
        textbox_placeholder: "Anza kuandika...",
        search_placeholder: "Tafuta...",
        no_provider: "Hakuna mtoa huduma aliyekabidhiwa.",
        no_active_chat:
            "Huna mazungumzo yoyote yanayoendelea. Anza mazungumzo mapya kwa kubofya kitufe cha ujumbe mpya.",
    },
    header_messages: {
        title: "Ujumbe",
        view_all: "ONA YOTE",
        no_messages: "Hakuna ujumbe wa kuonyesha Bado",
    },
    support: {
        title: "Msaada",
        tech_support_email: "Barua Pepe ya Msaada wa Kiufundi",
        other_question: "Maswali Mengine",
        section1_title: "Msaada wa Kliniki (Masuala Yanayohusiana na Afya)",
        section1_description:
            "Katika hali ya dharura ya kutishia maisha au matibabu, piga 911 mara moja. Kwa maswali yasiyo ya dharura ya afya, tafadhali wasiliana na",
        section2_title:
            "Msaada wa Kiufundi (Masuala Yanayohusiana na Programu)",
        section2_description:
            "Msaada wetu wa kiufundi unapatikana kutoka saa 7:00 asubuhi hadi 4:00 jioni PST, Jumatatu hadi Ijumaa, isipokuwa likizo kuu. Maombi yoyote yaliyotumwa nje ya saa hizi yatashughulikiwa siku inayofuata ya kazi.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "Mpango wa Kuzuia Majeraha",
        heading:
            "Huna jeraha kwa sasa? Unafanya kazi kwenye dawati siku nzima?",
        description:
            "Kuanzisha mpango wa kukimbia, au unataka kuboresha mchezo wako wa gofu? Tuna mazoezi ambayo yatasaidia kuzuia maumivu na majeraha pamoja na kuimarisha maeneo unayohitaji kuboresha utendaji.",
        workplace_label: "Mikakati ya Mahali pa Kazi",
        sport_label: "Mikakati ya Michezo",
        speciality_label: "Mikakati Maalum",
        generalized_label: "Mikakati ya Jumla",
        start_plan: "Anza Mpango",
    },
    footer: {
        copyright_text: "Everflex Plus Health | Haki Zote Zimehifadhiwa",
        pat_no_text:
            "Bidhaa hizi zimefunikwa na Pat. No. US 11,587,687 na maombi mengine yanayoendelea.",
        about: "Kuhusu",
        security_statement: "Taarifa ya Usalama",
        privacy_policy: "Sera ya Faragha",
        terms_conditions: "Masharti na Masharti",
        contact: "Wasiliana",
        support: "Msaada",
        faqs: "Maswali Yanayoulizwa Mara kwa Mara",
        patent_information_text: "Taarifa ya Hati Miliki",
    },
    my_profile: {
        head_title_text: "Profaili",
        my_account_text: "Akaunti Yangu",
        tab: {
            account_information_text: "Taarifa za Akaunti",
            communication_preferences_text: "Mapendeleo ya Mawasiliano",
            change_password_text: "Badilisha Nenosiri",
        },
        account_information_form: {
            page_heading_text: "Taarifa za Akaunti",
            first_name_field: {
                label_text: "Jina la Kwanza",
                placeholder_text: "Ingiza Jina la Kwanza",
            },
            last_name_field: {
                label_text: "Jina la Mwisho",
                placeholder_text: "Ingiza Jina la Mwisho",
            },
            dob_field: {
                label_text: "Tarehe ya Kuzaliwa",
                placeholder_text: "Tarehe ya Kuzaliwa (MM/DD/YYYY)",
            },
            email_field: {
                label_text: "Barua Pepe/Jina la Mtumiaji",
                placeholder_text: "Ingiza Barua Pepe/Jina la Mtumiaji",
            },
            phone_number_field: {
                label_text: "Nambari ya Simu",
                placeholder_text: "Ingiza Nambari ya Simu",
            },
            otp_modal: {},
            unverified_email_text:
                "Barua pepe/jina la mtumiaji wako halijathibitishwa.",
            unverified_email_btn_text:
                "Bofya hapa kutuma tena barua pepe ya uthibitishaji.",
            verification_link_sent_text:
                "Kiungo kipya cha uthibitishaji kimetumwa kwa anwani yako ya barua pepe.",
            verification_link_not_sent:
                "Tafadhali toa anwani ya barua pepe ili kuthibitisha.",
            submit_btn_text: "Wasilisha",
        },
        change_password_form: {
            page_heading_text: "Taarifa za Kuingia",
            current_password_field: {
                label_text: "Nenosiri Lako la Sasa",
                placeholder_text: "Ingiza Nenosiri la Sasa",
            },
            new_password_field: {
                label_text: "Nenosiri Jipya",
                placeholder_text: "Ingiza Nenosiri Jipya",
            },
            confirm_password_field: {
                label_text: "Thibitisha Nenosiri",
                placeholder_text: "Ingiza Thibitisha Nenosiri",
            },
            submit_btn_text: "Wasilisha",
        },
        communication_preference_form: {
            page_heading_text: "Mapendeleo ya Mawasiliano",
            table_headings: {
                setting_text: "Mpangilio",
                opt_in_text: "Jiunge",
                opt_out_text: "Jiondoe",
            },
            table_body: {
                email_notification_text: "Arifa za Barua Pepe",
                sms_notification_text: "Arifa za Ujumbe wa Simu (SMS)",
            },
            submit_btn_text: "Wasilisha",
        },
    },
    password_requirement_text:
        "Nenosiri lazima liwe na herufi angalau 8, likiwa na mchanganyiko wa herufi kubwa na ndogo, nambari, na alama.",
    language: {
        could_not_update: "Haikuweza kusasisha lugha.",
    },
    error_page: {
        go_back_text: "Rudi Nyumbani",
        403: {
            title: "403: Marufuku",
            description: "Samahani, umekatazwa kufikia ukurasa huu.",
        },
        404: {
            title: "404: Ukurasa Haukupatikana",
            description: "Samahani, ukurasa unaotafuta haukupatikana.",
        },
        500: {
            title: "500: Hitilafu ya Seva",
            description: "Whoops, kitu kilienda vibaya kwenye seva zetu.",
        },
        503: {
            title: "503: Huduma Haipatikani",
            description:
                "Samahani, tunafanya matengenezo. Tafadhali angalia tena hivi karibuni.",
        },
    },
    identify_injury: {
        select: {
            front: "Mbele",
            back: "Nyuma",
            heading: "Jeraha Langu",
            page_title: "Jeraha langu",
            okay: "Sawa",
            tell_me: "niambie",
            about_pain: "kuhusu maumivu yako",
            answer_question:
                "Jibu maswali kadhaa, na upokee mfululizo wa mazoezi yaliyoundwa na wataalamu wa tiba ya mwili ili kusaidia kupunguza maumivu kwa magonjwa 23 yanayoripotiwa mara nyingi. Ikiwa huna jeraha, unaweza kupata msaada wa kujisaidia na Mipango yetu ya Jumla, tafuta kliniki ya tiba ya mwili karibu nawe, au tembelea mtoa huduma mtandaoni.",
            select_body_part:
                "Chagua sehemu moja ya mwili ambapo una matatizo:",
            next: "Ifuatayo",
            body_part_not_found: "Sehemu ya mwili haikupatikana.",
        },
        show: {
            no_question_found: "Hakuna Swali lililopatikana",
            next: "Ifuatayo",
            heading: "Tambua Jeraha Langu",
            page_title: "Tambua Jeraha Langu",
            play_video_info:
                "Cheza video ili kusikia maelezo zaidi ya swali kutoka kwa mtaalamu wako wa tiba ya mwili wa mtandaoni.",
        },
        body_part: {
            heading: (bodyPart) => `Chunguza Tathmini ya ${bodyPart}`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `Hukupata nafuu kutoka kwa mazoezi ya ${currentBodyPart}? Elekeza kutathmini na kushughulikia matatizo yanayohusiana na ${newBodyPart}.`,
            explore_plans: "Chunguza Mipango",
        },
        fallout: {
            heading:
                "Kulingana na taarifa ulizotoa, hukupatana na mojawapo ya mifumo yetu ya jeraha.",
            sub_heading:
                "Inashauriwa uongee na mtaalamu wa tiba ya mwili aliyeidhinishwa ili kuhakikisha unapata huduma bora zaidi. Tunatoa chaguzi zifuatazo ili kukidhi mahitaji yako:",
            talk_to_expert: "Ongea na mtaalamu",
            find_clinic: "Tafuta kliniki",
        },
        generalized: {
            heading: "Chunguza Mipango ya Jumla",
            sub_heading:
                "Hukupata unachohitaji? Elekeza kwenye chaguzi pana zaidi za kudhibiti maumivu yako au kuboresha mazoezi yako.",
            explore_plans: "Chunguza Mipango",
            not_ready_to_speak_heading: "Je, hauko tayari kuzungumza na mtaalamu? Chunguza mipango yetu ya jumla.",
            not_ready_to_speak_sub_heading: "Bofya hapa chini kwa chaguo zaidi za kukusaidia kudhibiti maumivu au jeraha lako",
        },
        hep: {
            heading: "Umefanya Vizuri 🎉",
            sub_heading:
                "Majibu yako yamefanikiwa kulingana na mfumo wa jeraha uliowekwa. Tumekupa mpango wa kupona unaolingana na matokeo haya.",
            get_started: "anza",
        },
        all_body_parts: {
            neck: "Shingo",
            shoulder: "Bega",
            elbow_hand: "Kiwiko & Mkono",
            elbow_arm: "Kifundo cha mkono",
            wrist_hand: "Kifundo cha mkono & Mkono",
            back: "Mgongo",
            hip: "Hips",
            knee: "Goti",
            knee_leg: "Goti & Mguu",
            ankle_foot: "Kifundo cha mguu & Mguu",
        },
        something_went_wrong_self_help_logs:
            "Kitu kilienda vibaya katika kuhifadhi kumbukumbu yako ya kujisaidia.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "Kuhusu",
            head_text: "Kuhusu",
            title_text: "Kuhusu",
        },
        security_statement: {
            breadcrumb_text: "Taarifa ya Usalama",
            head_text: "Taarifa ya Usalama",
            title_text: "Taarifa ya Usalama",
        },
        privacy: {
            breadcrumb_text: "Sera ya Faragha",
            head_text: "Sera ya Faragha",
            title_text: "Sera ya Faragha",
        },
        terms: {
            breadcrumb_text: "Masharti na Masharti",
            head_text: "Masharti na Masharti",
            title_text: "Masharti na Masharti",
        },
        faq: {
            breadcrumb_text: "Maswali Yanayoulizwa Mara kwa Mara",
            head_text: "Maswali Yanayoulizwa Mara kwa Mara",
            title_text: "Maswali Yanayoulizwa Mara kwa Mara",
        },
        patent: {
            breadcrumb_text: "Taarifa ya Hati Miliki",
            head_text: "Taarifa ya Hati Miliki",
            title_text: "Taarifa ya Hati Miliki",
        },
        no_data_text: "Hakuna Data Iliyopatikana!",
    },
    accept_terms: {
        i_agree_text:
            "Nakubali masharti na masharti kama ilivyoelezwa hapo juu.",
        yes_btn_text: "Ndio",
        no_btn_text: "Hapana",
    },
    hep_restart: {
        check_in: "Wakati wa Kuangalia",
        completed: "Mpango Wako Umekamilika!",
        hey: "Habari,",
        complete_plan_desc: `Tunafurahi kusherehekea maendeleo mazuri uliyoyapata katika siku 21 zilizopita na wewe!`,
        take_a_moment: `Chukua muda kutafakari jinsi unavyohisi hadi sasa.`,
        feeling_better: `Unajisikia vizuri? Nzuri!`,
        current_plan_desc: `Unaweza kubofya kitufe cha KAMILISHA MPANGO WANGU WA SASA hapa chini. Hongera kwa afya yako!`,
        resume_plan_desc: `Kuendelea na mpango wako na kudumisha kasi, bofya kitufe cha ENDELEA NA MPANGO WANGU hapa chini.`,
        clinic_desc: `Ikiwa hujapata maendeleo mengi au unahitaji msaada wa ziada, tuko hapa kwa ajili yako! Ni sawa kusitisha. Tunapendekeza kuzungumza na mtaalamu wa afya ambaye anaweza kutoa mwongozo wa kibinafsi.`,
        top_priority: `Afya yako ni kipaumbele chetu cha juu.`,
        support_you: `Tuko hapa kukusaidia kila hatua ya njia.`,
        complete_plan_btn: `Kamilisha mpango wangu wa sasa`,
        resume_plan_btn: `Endelea na mpango wangu wa sasa`,
        clinic_btn: "Tafuta mtaalamu wa afya karibu nawe",
        keep_moment: "Je, ungependa kuendelea na kasi?",
        is_completed_resume_plan: `Bofya kitufe cha ENDELEA NA MPANGO WANGU hapa chini.`,
    },
    header_module: {
        notification_section: {
            popup_heading: "Arifa",
            view_all_text: "Tazama Yote",
            no_data_text: "Hakuna arifa za kuonyesha.",
        },
        select_preferred_language: "Chagua Lugha Unayopendelea",
        my_account: "Akaunti Yangu",
        sign_out: "Toka",
        logout_go_back: "Toka/Rudi Nyuma",
    },
    notification_center: {
        list: {
            page_title: "Orodha ya Kituo cha Arifa",
            page_heading: "Arifa Zote",
            no_record: "Hakuna Arifa Zilizopatikana",
        },
        show: {
            page_title: "Onyesha Kituo cha Arifa",
            page_heading: "Maelezo ya Arifa",
        },
        breadcrumb: {
            notification_text: "Arifa Zote",
        },
        action: {
            view: "Tazama",
            delete: "Futa",
            delete_confirm: "Una uhakika unataka kufuta arifa hii?",
        },
    },
    general_error: "Kitu kilienda vibaya. Tafadhali jaribu tena baadaye.",
    start_screen: {
        title: "Ingiza barua pepe yako au nambari ya simu",
        heading: "Ingiza barua pepe yako au nambari ya simu",
        email_placeholder: "Ingiza barua pepe yako au nambari ya simu",
        btn_text: "Ifuatayo",
        account_disabled_error:
            "Akaunti yako imezimwa, Tafadhali wasiliana na msaada.",
        invite_sent:
            "Barua pepe ya mwaliko imetumwa kwa mafanikio, tafadhali angalia barua pepe yako.",
        invite_sent_by_text: "Ujumbe wa mwaliko umetumwa",
        send_invite_by_text: "Tuma mwaliko kwa ujumbe badala yake",
        account_not_found: "Akaunti haikupatikana!",
        choose_account: "Chagua Kliniki Yako Kuendelea!",
    },
    invite_modal: {
        heading: "Tumepata Akaunti Yako",
        sub_heading:
            "Akaunti yako imeundwa, lakini mchakato wa kujisajili haujakamilika. Tafadhali angalia barua pepe yako kwa mwaliko wa kukamilisha usajili wako, au omba mwaliko mpya ikiwa ni lazima.",
        btn_text: "Tuma Tena Barua Pepe ya Mwaliko wa Akaunti",
        btn_text_sms: "Tuma Tena SMS ya Mwaliko wa Akaunti",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `Ninaidhinisha kwamba mawasiliano yafuatayo kutoka ${clinicName} yaletwe kwangu kwa njia za kielektroniki zilizotolewa. Ninaelewa kwamba baadhi ya aina za mawasiliano ya kielektroniki zinaweza kuwa si salama, na hivyo kuunda hatari ya kufichuliwa vibaya kwa watu wasioidhinishwa.`,
        acceptance_text_2: `Niko tayari kukubali hatari hiyo, na sitashikilia kliniki kuwajibika iwapo tukio kama hilo litatokea.`,
        sms_notification_text: `Arifa za Ujumbe wa Simu (SMS) - inapofaa kwa mpango wako wa kupona na kuteuliwa na mtoa huduma wako`,
        email_notification_text: `Arifa za Barua Pepe (sasisho muhimu, vikumbusho vya taarifa nyingine zinazohusiana na afya, na ujumbe wa masoko mara kwa mara)`,
        marketing_text: "Marknadsföringsmejl - Ta emot utbildningsinnehåll, tjänster eller produktuppdateringar anpassade efter dina intressen och din aktivitet i portalen. OBS: Vi kommer ALDRIG att sälja din information till en tredje part.",
        clinical_text: "Kliniska mejl - Ta emot viktiga uppdateringar och information relaterad till din behandling och din hälsorelaterade resa.",
        system_text: "Systemmeddelanden - Håll dig informerad med viktiga mejl om systemuppdateringar och säkerhetsvarningar.",
        system: {
            header: "Systemmeddelanden",
            subtext: "Håll dig informerad med viktiga mejl om systemuppdateringar och säkerhetsvarningar.",
        },
        clinical: {
            header: "Kliniska mejl",
            subtext: "Ta emot viktiga uppdateringar och information relaterad till din behandling och din hälsorelaterade resa.",
        },
        marketing: {
            header: "Marknadsföringsmejl",
            subtext: "Ta emot utbildningsinnehåll, tjänster eller produktuppdateringar anpassade efter dina intressen och din aktivitet i portalen. OBS: Vi kommer ALDRIG att sälja din information till en tredje part.",
        },
        accept_btn_text: "Kubali",
        submit_btn_text: "Wasilisha",
        enter_phone: "INGIZA NAMBA YAKO YA SIMU YA MKONONI",
        default_timezone_text: (defaultTimezone) =>
            `Tutakutumia kikumbusho cha maandishi kufanya mazoezi yako saa 6:00 PM kwa saa za eneo lako. Inaonekana uko katika eneo la saa ${defaultTimezone}, je, ni sahihi?`,
        timezone_required: "Eneo la saa linahitajika.",
        saved: "Mapendeleo yamehifadhiwa.",
        no_btn_text: "Hapana",
        yes_btn_text: "Ndio",
        select_timezone: "Chagua Eneo la Saa",
        select_your_timezone: "Chagua eneo lako la saa",
    },
    set_new_password: {
        heading: "WEKA NENOSIRI LAKO JIPYA",
        sub_heading:
            "Kwa usalama zaidi, tafadhali ingiza nenosiri lako la sasa lililotolewa na msimamizi na kisha weka nenosiri lako jipya.",
        current_password_label: "Nenosiri la Sasa",
        current_password_placeholder: "Ingiza Nenosiri la Sasa",
        new_password_label: "Nenosiri Jipya",
        new_password_placeholder: "Ingiza Nenosiri Jipya",
        confirm_password_label: "Thibitisha nenosiri jipya",
        confirm_password_placeholder: "Thibitisha nenosiri",
        save_btn_text: "Hifadhi Nenosiri",
    },
    hep_status: {
        completed: "Imekamilika",
        self_assigned: "Imepewa Mwenyewe",
        independent: "Mpango wa Nyumbani Huru",
    },
    level: "Kiwango",
    sec: "Sekunde",
    days: "Siku",
    repetitions: "Marudio",
    lbs: "Pauni",
    time: "Muda",
    allLanguages: {
        en: "Kiingereza",
        es: "Kihispania",
        fr: "Kifaransa",
        zh: "Kichina (Rahisi)",
        "zh-TW": "Kichina (Kienyeji)",
        tl: "Kitagalogi",
        vi: "Kivietinamu",
        ar: "Kiarabu",
        ko: "Kikorea",
        ru: "Kirusi",
        hi: "Kihindi",
        sw: "Kiswahili",
    },
};
